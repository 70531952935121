import * as React from 'react';
import { map as _map } from 'lodash';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';
import ImageBanner from '@lce/intl-ui/src/PageComponents/AboutUs/ImageBanner/ImageBanner';
import AboutUsStack from '@lce/intl-ui/src/PageComponents/AboutUs/AboutUsStack';

import Layout from '../ui/ConnectedLayout';
import useAboutUs from '../api/AboutUsPage/useAboutUs';

const AboutUsPage: React.FC<IDefaultPageProps> = props => {
  const { aboutUsBanner, aboutUsPanel } = useAboutUs(props.pathContext.locale);

  return (
    <Layout {...props} >
      <Container sx={{ mb: '12px', px: '16px' }}>
        {aboutUsBanner && <ImageBanner {...aboutUsBanner} />}
        {_map(aboutUsPanel, (panelItem, index) => (
          <AboutUsStack
            description={panelItem.description}
            image={panelItem.panelImage}
            isReversed={index % 2 === 1}
            key={index}
            title={panelItem.title}
          />
        ))}
      </Container>
    </Layout>
  );
};

export default AboutUsPage;
